/** Kategorie menu */
.menu-3, .producenci-list{
  @extend .list-group;
  li{
    @extend .list-group-item;
    @extend .list-group-item-action;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-between;
    @extend .flex-wrap;
    @extend .p-0;
    ul{
      @extend .w-100;
      @extend .pl-0;
      @extend .list-group;
      @extend .list-group-flush;
      @extend .bg-kpsl-3;
      &.collapse.show li{
        padding-left:1rem !important;
      }
    }
    a{
      @extend .px-3;
      @extend .py-3;
      @extend .text-decoration-none;
      @extend .text-kpsl-4;
      @extend .text-uppercase;
      @extend .font-weight-bold;
      font-size: $font-size-xs;
      flex: 1;
    }
    button{
      @extend .mx-3;
    }
    &:not(:hover):not(.active){
      @extend .bg-transparent;
    }
    &.active{
      @extend .bg-kpsl-3;
      @extend .rounded-0;
      border-top-color: rgba(0, 0, 0, 0.125);
      border-bottom-color: rgba(0, 0, 0, 0.125);
      & > a{
        &:hover{
          @extend .text-light;
        }
      }
      
    }
    
  }
  button{
    @extend .btn;
    @extend .btn-outline-kpsl-4;
    @extend .btn-sm;
    @extend .p-1;
    @extend .rounded-circle;
    i{
      height: 1rem;
      width: 1rem;
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-center;
    }
    transform: rotate(180deg);
    will-change:transform;
    transition: transform .15s ease-in;
    &.active, &.collapsed{
      transform: none;
    }
  }
  
  li ul:not(.show):not(.collapsing){
    display: none !important;
  }
  
}

.errors {
    padding: 0;
    list-style: none;

    & > li {
        background-color: #f9f2f4;
        border-radius: 4px;
        color: #c7254e;
        font-size: 90%;
        padding: 2px 4px;
    }
}
/** Bootstrap helpers*/
.text-size-base{
  font-size: $font-size-base;
}
.text-size-xs{
  font-size: $font-size-xs;
}
.text-size-sm{
  font-size: $font-size-sm;
}
.text-size-lg{
  font-size: $font-size-lg;
}

/** Owl Carousel FIX dla responsive flexbox */
.owl-carousel-wrapper{
  min-width: 0;
}
.owl-carousel{
  
}
.owl-nav{
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: calc(100% + 2rem);
  height: 100%;
  top: 0;
  left: -1rem;
  pointer-events: none;
  .owl-prev, .owl-next{
    width: 1rem;
    pointer-events: auto;
  }
}

.owl-carousel-header{
  height: 100%;
  .owl-stage-outer{
    height: 100%;
    transform: skewX(-27deg);
    border-left: 4px solid $white;
    border-right: 4px solid $white;
    .owl-stage{
      height: 100%;
      .owl-item{
        height: 100%;
        .item{
          display: flex;
          align-items: flex-end;
          height: 100%;
          border-left: 4px solid $white;
          border-right: 4px solid $white;
          margin-left: -4px;
          text-align: center;
          padding: 1.5rem 2rem;
          text-decoration: none;
          color: $white;
          position: relative;
          overflow: hidden;
          &::before{
            transition: background-color .15s ease-in;
            content:"";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            z-index: 1;
          }
          &:hover::before{
            background-color: rgba(37, 156, 210, .85);
          }
          .img-container{
            
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            transform: skewX(27deg);
            position: absolute;
            top: 0;
            left: -5rem;
            right: -5rem;
            bottom: 0;
          }
          .text-container{
            transform: skewX(27deg);  
            width: 100%;
            line-height: 1;
            font-size: 2.25rem;
            font-weight: 700;
            z-index: 2;
          }
        }
      }
    }
  }
}

.predash{
  &.display-4 {
   font-size: 1.75rem !important; 
  }
  &.h3{
      font-size: 1.125rem;
    }
  &::before{
    content: "";
    display: inline-block;
    width: 2rem;
    border-bottom: .5rem solid $kpsl-2;
    margin-right: .5rem;
  }
  &.predash-sm{
    &::before{
      width: 1.25rem;
      border-bottom-width: .25rem;
      margin-right: .25rem;
    }
  }
  &.predash-blue{
    &::before{
      border-bottom-color: $kpsl-1;
    }
  }
}
.logo-budowlany{
  word-spacing: -.25rem;
}

/** galeria zdjec **/
#produkt-foto{
  .fotka{
    height: 15rem;
  }
  .img-resize{
    position: absolute;
    bottom: 0;
    border-radius: 0;
  }
  .slick-next, .slick-prev{
    &::before{
      content: "";
    }
  }
}

/** Stylowanie kalkulatora deski */
#deska-form-carousel{
    &.carousel-fade .active.carousel-item-left, &.carousel-fade .active.carousel-item-right {
      z-index: 0;
      opacity: 0;
      transition: .6s opacity;
  }
}

#deska-form, #deska-form-oferta, #daneosobowe{
  .input-group-prepend.label-header label{
    justify-content: flex-end;
    width: 10rem;
    @include media-breakpoint-only(xs){
      width: 7rem;
      font-size: .75rem;
    }
  }  
  fieldset{
    transition: opacity .15;
    opacity: 1;
    &[disabled]{
      opacity: .5;
    }
  }
}
body{
  /** Potrzebne dla position sticky */
  #topnav{
    top: 0;
    z-index: 1000;
  }
  .navbar-brand{
    font-size: .875rem;
  }
  /** Modal z nawigacja mobilna */
  &.modal-open{
    position: fixed;
  }
  #xs-navigation{
    &.modal{
      &.show{
        .modal-dialog{
          transform: none;
        }
      }
      .modal-dialog{
        position: fixed;
        height: 100%;
        margin: 0;
        max-width: none;
        width: 90%;
        transition: transform .15s ease-in;
        will-change: transform;
        transform: translateX(-100%);
        .modal-content{
          height: 100%;
          overflow-y:auto;
          border-radius: 0;
          border: none;
          
          .menu-3{
            @extend .bg-kpsl-3;
          }
        }
      }
    }
  }
  #lista-produktow, .carousel-lista-produktow{
    .card-img-top{
      height: 10rem;
      object-fit: contain;
      background-color: #fff;
    }
    
    .card-text{
      font-size: .875rem;
    }
    .card{
      will-change: transform;
      transform: none;
      transition: transform .15s ease-in;
      position: relative;
    }
    .card:hover{
        transform: translateY(-1rem);
        &::before{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1000;
          pointer-events: none;
          box-shadow: 0 0 2rem rgba(123, 123, 123, 0.3) inset;
        }
    }
    .card-img-overlay .card-title{
      font-size: .75rem;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .carousel-lista-produktow{
    .card-title{
      height: 5rem;
      margin-bottom: 1.5rem;
    }
  }
  #bottom{
    #about-us{
      p{
        line-height: 1.8;
        margin-bottom: 2rem;
      }
    }
  }
  .edytorek{
    h1{
      /*@extend .predash;*/
    }
  }
  .js-input-group-counter{
      flex-wrap: nowrap;
      input, a{
      }
      input[type="number"] {
          -webkit-appearance: textfield;
             -moz-appearance: textfield;
                  appearance: textfield;
      }
      input[type=number]::-webkit-inner-spin-button, 
      input[type=number]::-webkit-outer-spin-button { 
          -webkit-appearance: none;
      }
  }
  .btn-rounded{
    @extend .btn;
    @extend .btn-outline-kpsl-4;
    @extend .btn-sm;
    @extend .p-1;
    @extend .rounded-circle;
    i{
      height: 1rem;
      width: 1rem;
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-center;
    }
    transform: rotate(180deg);
    will-change:transform;
    transition: transform .15s ease-in;
    &.active{
      transform: none;
    }
  }
}

#li_kategoria-aktywna{
  background-color: $kpsl-4 !important;
}
#kategoria-aktywna{
  text-decoration: underline !important;
  color: $white !important;
}

#lista-kasa{
  td, th{
    vertical-align: middle;
  }
}

#column-1 a.d-block{
  text-decoration: none;
  position: relative;
  &:hover::before{
    content:"";
    display:block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: rgba(0, 0, 0, .15);
  }
  .card-img-top{
    height: 10rem;
    width: auto;
    max-width: 100%;
  }
}