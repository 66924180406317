@include media-breakpoint-up(xxl){
  body{
    /** Owl Carousel FIX dla responsive flexbox */
    .owl-nav{
      width: calc(100% + 12rem);
      left: -6rem;
      .owl-prev, .owl-next{
        width: 6rem;
      }
    }
  }
}